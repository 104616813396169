<template>
  <Modal
    v-if="isNotSupportedBrowser"
    :destroyOnClose="true"
    :closable="false"
    :maskClosable="false"
    :footer="null"
    v-model:visible="visible"
    @onCancel="handleCancel"
    :zIndex="1000"
  >
    <div class="sm:flex sm:items-start">
      <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gs-purple/10 sm:mx-0 sm:h-10 sm:w-10">
        <ExclamationTriangleIcon class="h-6 w-6 text-gs-purple" aria-hidden="true" />
      </div>
      <div class="mt-3 text-sm text-gray-500 text-center sm:ml-4 sm:mt-0 sm:text-left space-y-2">
        <h3 class="text-base font-semibold leading-6 text-gray-900">{{ MsgBrowserRecommendation }}</h3>
        <p>
          {{ MsgBrowserUnsupported }}
        </p>
      </div>
    </div>
    <div class="mt-5 sm:ml-10 sm:mt-4 sm:pl-4">
      <Checkbox v-model:checked="dontShowAgain" class="text-gray-500">{{ MsgDontShowAgain }}</Checkbox>
    </div>
    <div class="mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4">
      <a
        :href="chromeLink"
        target="_blank"
        type="button"
        class="inline-flex w-full justify-center rounded-md bg-gs-purple px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-gs-purple/70 hover:text-slate-100 transition-colors sm:w-auto"
      >
        {{ MsgDownloadGoogleChrome }}
      </a>
      <button
        type="button"
        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-normal text-slate-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
        @click="handleCancel"
      >
        {{ MsgClose }}
      </button>
    </div>
  </Modal>
</template>
<script lang="ts" src="./unsupported-browser-warning.ts"></script>
