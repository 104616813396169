import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "sm:flex sm:items-start" }
const _hoisted_2 = { class: "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gs-purple/10 sm:mx-0 sm:h-10 sm:w-10" }
const _hoisted_3 = { class: "mt-3 text-sm text-gray-500 text-center sm:ml-4 sm:mt-0 sm:text-left space-y-2" }
const _hoisted_4 = { class: "text-base font-semibold leading-6 text-gray-900" }
const _hoisted_5 = { class: "mt-5 sm:ml-10 sm:mt-4 sm:pl-4" }
const _hoisted_6 = { class: "mt-5 sm:ml-10 sm:mt-4 sm:flex sm:pl-4" }
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationTriangleIcon = _resolveComponent("ExclamationTriangleIcon")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.isNotSupportedBrowser)
    ? (_openBlock(), _createBlock(_component_Modal, {
        key: 0,
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
        footer: null,
        visible: _ctx.visible,
        "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visible) = $event)),
        onOnCancel: _ctx.handleCancel,
        zIndex: 1000
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ExclamationTriangleIcon, {
                class: "h-6 w-6 text-gs-purple",
                "aria-hidden": "true"
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.MsgBrowserRecommendation), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.MsgBrowserUnsupported), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Checkbox, {
              checked: _ctx.dontShowAgain,
              "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dontShowAgain) = $event)),
              class: "text-gray-500"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.MsgDontShowAgain), 1)
              ]),
              _: 1
            }, 8, ["checked"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("a", {
              href: _ctx.chromeLink,
              target: "_blank",
              type: "button",
              class: "inline-flex w-full justify-center rounded-md bg-gs-purple px-3 py-2 text-sm font-normal text-white shadow-sm hover:bg-gs-purple/70 hover:text-slate-100 transition-colors sm:w-auto"
            }, _toDisplayString(_ctx.MsgDownloadGoogleChrome), 9, _hoisted_7),
            _createElementVNode("button", {
              type: "button",
              class: "mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-normal text-slate-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleCancel && _ctx.handleCancel(...args)))
            }, _toDisplayString(_ctx.MsgClose), 1)
          ])
        ]),
        _: 1
      }, 8, ["visible", "onOnCancel"]))
    : _createCommentVNode("", true)
}