import { DisconnectScreenLocale, ErrorLocale, TeacherClassError } from "@/locales/localeid";
import { store } from "@/store";
import { Logger } from "@/utils/logger";
import AgoraRTC, {
  CameraVideoTrackInitConfig,
  ClientConfig,
  ConnectionDisconnectedReason,
  ConnectionState,
  IAgoraRTC,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  ILocalTrack,
  IMicrophoneAudioTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  NetworkQuality,
  UID,
  VideoEncoderConfigurationPreset,
} from "agora-rtc-sdk-ng";
import {
  AIDenoiserExtension,
  AIDenoiserProcessor,
  AIDenoiserProcessorLevel,
  AIDenoiserProcessorMode,
} from "agora-extension-ai-denoiser";
import { Modal, notification } from "ant-design-vue";
import { fmtMsg } from "vue-glcommonui";
import { debounce } from "lodash";
import { isChromiumBasedBrowser } from "@/utils/utils";

export interface AgoraClientSDK {
  client: IAgoraRTCClient;
  joinRTCRoom(options: JoinRoomOptions, reInit: boolean, callbackWhenJoinFailed?: () => Promise<any>): void;
}

const AGORA_LOW_NETWORK_QUALITY_THRESHOLD = 2;
const LOW_VIDEO_ENCODER_CONFIG = "180p_4";
const HIGH_VIDEO_ENCODER_CONFIG = "480p_1";
// After four times of low network quality, switch to low video encoder config
const SWITCH_VIDEO_CALL_QUALITY_TIMEOUT = 8000;
const calcEncoderConfigBasedOnBitrate = (sendResolutionWidth: number) => {
  if(!sendResolutionWidth || sendResolutionWidth < 640) {
    return LOW_VIDEO_ENCODER_CONFIG;
  } else {
    return HIGH_VIDEO_ENCODER_CONFIG;
  }
}
export enum CallingDeviceType {
  MICROPHONE = "microphone",
  CAMERA = "camera",
}
export interface AgoraUser {
  channel: string;
  username: string;
  role: "host" | "audience";
  token: string;
}
export interface AgoraClientOptions {
  appId: string;
  webConfig: ClientConfig;
  user?: AgoraUser;
}
export interface AgoraEventHandler {
  onException(payload: any): void;
  onLocalNetworkUpdate(payload: any): void;
  onConnectionStateChange(currentState: ConnectionState, prevState: ConnectionState, reason: ConnectionDisconnectedReason | undefined): void;
  onUserJoined(payload: IAgoraRTCRemoteUser): void;
  onUserLeft(payload: IAgoraRTCRemoteUser, reason: string): void;
}
export interface JoinRoomOptions {
  camera?: boolean;
  isMirror?: boolean;
  isRemoteMirror?: boolean;
  videoEncoderConfigurationPreset?: string;
  microphone?: boolean;
  callingEventHandlers: AgoraEventHandler | null;
}
const LIMIT_COUNT = 30;
const INIT_COUNT = 1;
let extension: AIDenoiserExtension | null = null;
let processor: AIDenoiserProcessor | null = null;
export class AgoraClient implements AgoraClientSDK {
  _client?: IAgoraRTCClient;
  _options: AgoraClientOptions;
  _joinRoomOptions?: JoinRoomOptions;
  _cameraTrack?: ICameraVideoTrack;
  _microphoneTrack?: IMicrophoneAudioTrack;
  _callbackWhenJoinFailed?: () => Promise<any>;
  get cameraTrack(): ICameraVideoTrack {
    return this._cameraTrack as ICameraVideoTrack;
  }
  get microphoneTrack(): IMicrophoneAudioTrack {
    return this._microphoneTrack as IMicrophoneAudioTrack;
  }
  get options(): AgoraClientOptions {
    return this._options as AgoraClientOptions;
  }
  get user(): AgoraUser {
    return this._options.user as AgoraUser;
  }
  get client(): IAgoraRTCClient {
    return this._client as IAgoraRTCClient;
  }
  get clientConfig(): ClientConfig {
    return this.options.webConfig as ClientConfig;
  }
  get joinRoomOptions(): JoinRoomOptions | undefined {
    return this._joinRoomOptions;
  }
  get agoraRTC(): IAgoraRTC {
    return AgoraRTC;
  }
  get isCurrentUserStudent() {
    return !!store.getters["studentRoom/isLoggedInAsStudent"];
  }
  get isAINoiseCancellingEnabled() {
    return store.getters["calling/enabledAgoraNoiseCancelling"];
  }
  constructor(options: AgoraClientOptions) {
    this._options = options;
  }
  joined: boolean = false;
  isMirror: boolean = false;
  isRemoteMirror: boolean = false;
  publishedVideo: boolean = false;
  publishedAudio: boolean = false;
  publishedVideosTimeout: any = {};
  publishedAudiosTimeout: any = {};
  joinCounter = 0;
  initialized: boolean = false;
  async joinRTCRoom(options: JoinRoomOptions, reInit: boolean, callbackWhenJoinFailed?: () => Promise<any>) {
    if (!this._joinRoomOptions) {
      this._joinRoomOptions = options;
    }
    if (!this._callbackWhenJoinFailed) {
      this._callbackWhenJoinFailed = callbackWhenJoinFailed;
    }
    if ((this._client && this.joined) || !options.callingEventHandlers) return;
    if (reInit) Logger.log("AGORA START REINIT");
    else Logger.log("AGORA START INIT");
    try {
      if (!this._client) {
        this._client = this.agoraRTC.createClient(this.clientConfig);
        this.isMirror = !!options?.isMirror;
        this.isRemoteMirror = !!options?.isRemoteMirror;
        const { onException, onLocalNetworkUpdate, onUserLeft, onUserJoined, onConnectionStateChange } = options.callingEventHandlers;
        this.client?.on("user-left", onUserLeft);
        this.client?.on("user-joined", (payload: any) => {
          onUserJoined(payload);
        });
        this.client.on("user-published", async (user, mediaType) => {
          if (mediaType === "video") {
            if (this.publishedVideosTimeout[user.uid]) {
              clearTimeout(this.publishedVideosTimeout[user.uid]);
            }
            this.publishedVideosTimeout[user.uid] = setTimeout(() => {
              for (const [index, { userId }] of this.subscribedVideos.entries()) {
                if (userId === user.uid) {
                  this.subscribedVideos.splice(index, 1);
                }
              }
              this.triggerUpdateAudioAndVideoFeed();
            }, 500);
          }
          if (mediaType === "audio") {
            if (this.publishedAudiosTimeout[user.uid]) {
              clearTimeout(this.publishedAudiosTimeout[user.uid]);
            }
            this.publishedAudiosTimeout[user.uid] = setTimeout(() => {
              for (const [index, { userId }] of this.subscribedAudios.entries()) {
                if (userId === user.uid) {
                  this.subscribedAudios.splice(index, 1);
                }
              }
              this.triggerUpdateAudioAndVideoFeed();
            }, 500);
          }
        });
        this.client.on("user-unpublished", (user) => {
          if (this.publishedVideosTimeout[user.uid]) {
            clearTimeout(this.publishedVideosTimeout[user.uid]);
          }
          if (this.publishedAudiosTimeout[user.uid]) {
            clearTimeout(this.publishedAudiosTimeout[user.uid]);
          }
          this.triggerUpdateAudioAndVideoFeed();
        });
        this.client?.on("exception", onException);
        this.client?.on("network-quality", (payload: NetworkQuality) => {
          onLocalNetworkUpdate(payload);
          if(this.isCurrentUserStudent || !this.cameraTrack || !isChromiumBasedBrowser()) return;
          const { uplinkNetworkQuality, downlinkNetworkQuality } = payload;
          let newVEC;
          if (uplinkNetworkQuality > AGORA_LOW_NETWORK_QUALITY_THRESHOLD || downlinkNetworkQuality > AGORA_LOW_NETWORK_QUALITY_THRESHOLD) {
            newVEC = LOW_VIDEO_ENCODER_CONFIG;
          } else {
            newVEC = HIGH_VIDEO_ENCODER_CONFIG;
          }
          if (newVEC !== this.currentVideoEncoderConfiguration) {
            this.currentVideoEncoderConfiguration = newVEC;
            this.debouncedSetEncoderConfiguration(newVEC);
          }
        });
        this.client?.on("connection-state-change", onConnectionStateChange);
        this.client?.enableAudioVolumeIndicator();
        this.client.on("volume-indicator", (result: { level: number; uid: UID }[]) => {
          const joinAsHelper = store.getters["teacher/isHelper"];
          if (this.options.user?.role === "host" || joinAsHelper) {
            store.dispatch("teacherRoom/setSpeakingUsers", result);
          } else {
            store.dispatch("studentRoom/setSpeakingUsers", result);
          }
        });
        this.agoraRTC.setLogLevel(4);
      }
      if (reInit) Logger.log("AGORA END REINIT");
      else Logger.log("AGORA END INIT");
      try {
        await this.client.join(this.options.appId, this.user.channel, this.user.token, this.user.username);
        this.joined = true;
      } catch (error) {
        Logger.log(error);
        if (this.joinCounter > 3) {
          return Modal.error({
            title: () => fmtMsg(ErrorLocale.CallingServerConnectFailTitle),
            content: () => fmtMsg(ErrorLocale.CallingServerConnectFailDesc),
            onOk: () => (location.href = location.origin),
            okText: fmtMsg(DisconnectScreenLocale.Goto),
          });
        }
        this.joinCounter++;
        //make one more try to join Agora before throwing alert!
        setTimeout(async () => {
          try {
            await this.client.join(this.options.appId, this.user.channel, this.user.token, this.user.username);
            this.joined = true;
            await this._afterJoin(options);
          } catch (err) {
            //reset everything here so when signalR reconnect, Agora client may be re-init
            await this.reset();
            notification.error({ message: fmtMsg(TeacherClassError.ConnectAgoraServersError) });
            if (this._callbackWhenJoinFailed) {
              await this._callbackWhenJoinFailed();
            }
          }
        }, 3500);
      }
      if (this.joined) {
        await this._afterJoin(options);
      }
    } catch (err) {
      //reset everything here so when signalR reconnect, Agora client may be re-init
      await this.reset();
      notification.error({ message: fmtMsg(TeacherClassError.ConnectAgoraServersError), duration: 5000 });
      //   Let user read the message about 5s, after that auto reload.
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }

  currentVideoEncoderConfiguration: string = HIGH_VIDEO_ENCODER_CONFIG;

  debouncedSetEncoderConfiguration = debounce(async (nextConfig: VideoEncoderConfigurationPreset) => {
    try {
      if(!this.cameraTrack || (calcEncoderConfigBasedOnBitrate(this.cameraTrack.getStats().sendResolutionWidth) === nextConfig)) return;
      await this.cameraTrack.setEncoderConfiguration(nextConfig);
    } catch (error) {
      this.currentVideoEncoderConfiguration = calcEncoderConfigBasedOnBitrate(this.cameraTrack.getStats().sendResolutionWidth);
      Logger.error(error);
    }
  }, SWITCH_VIDEO_CALL_QUALITY_TIMEOUT);

  private async _afterJoin(options: JoinRoomOptions | undefined): Promise<any> {
    if (options?.camera) {
      try {
        await this.openCamera(options?.videoEncoderConfigurationPreset);
      } catch (e) {
        Logger.error(e);
        notification.error({
          message: fmtMsg(ErrorLocale.ToggleCameraError),
        });
      }
    }
    if (options?.microphone) {
      try {
        await this.openMicrophone();
      } catch (e) {
        Logger.error(e);
        notification.error({
          message: fmtMsg(ErrorLocale.ToggleMicroError),
        });
      }
    }
    this.registerHotPluggingDevicesEventListener();
    await this._publish();
    this.initialized = true;
  }
  subscribedVideos: Array<{
    userId: string;
    track: IRemoteVideoTrack;
  }> = [];
  subscribedAudios: Array<{
    userId: string;
    track: IRemoteAudioTrack;
  }> = [];
  microphoneError: {
    code: string;
    message: string;
  } | null = null;
  async openMicrophone(): Promise<void> {
    if (this._microphoneTrack) return;
    try {
      const track =  await this.agoraRTC.createMicrophoneAudioTrack({encoderConfig: "high_quality_stereo"});
      this._microphoneTrack = track;
      this.microphoneTrack.on("track-ended", () => {
        Logger.log("track-ended micro");
      });
      const micId = store.getters["microphoneDeviceId"];
      if (micId) {
        try {
          await this.microphoneTrack.setDevice(micId);
        } catch (error) {
          Logger.error(error);
        }
      }
      this.microphoneError = null;
      if(this.isAINoiseCancellingEnabled) {
        await this.settingNoiseCancelling(track);
      }
    } catch (err) {
      Logger.error(err);
      this.microphoneError = err;
      throw err;
    }
  }
  cameraError: {
    code: string;
    message: string;
  } | null = null;
  /**
   * Opens camera with the resolution is set to 240x180 for every user by default.
   * See {VideoEncoderConfigurationPreset} for more presets.
   * @param {string} videoEncoderConfigurationPreset
   */
  // TODO: Remove the videoEncoderConfigurationPreset parameter
  async openCamera(videoEncoderConfigurationPreset: string = LOW_VIDEO_ENCODER_CONFIG): Promise<void> {
    if (this._cameraTrack) return;
    try {
      const camId = store.getters["cameraDeviceId"];
      const config: CameraVideoTrackInitConfig = {
        encoderConfig: this.isCurrentUserStudent ? LOW_VIDEO_ENCODER_CONFIG : this.currentVideoEncoderConfiguration
      };
      if (camId) {
        config.cameraId = camId;
      }
      this._cameraTrack = await this.agoraRTC.createCameraVideoTrack(config);
      if (camId) {
        try {
          await this.cameraTrack.setDevice(camId);
        } catch (error) {
          Logger.error(error);
        }
      }
      if (!document.getElementById(this.user.username)) return;
      this.cameraTrack.play(this.user.username, { mirror: this.isMirror });
      this.cameraError = null;
    } catch (err) {
      Logger.error(err);
      this.cameraError = err;
      throw err;
    }
  }

  registerHotPluggingDevicesEventListener() {
    this.setupHotPluggingDevice(CallingDeviceType.CAMERA);
    this.setupHotPluggingDevice(CallingDeviceType.MICROPHONE);
  }
  async checkAndReconnectCamera() {
    if (this.cameraTrack && !this.cameraTrack.getVideoElementVisibleStatus()) {
      this.cameraTrack.play(this.user.username, { mirror: this.isMirror });
    }
  }
  private async _closeMediaTrack(track: ILocalTrack) {
    if (track) {
      try {
        track.stop();
        track.close();
        if (track.trackMediaType === "video") {
          this._cameraTrack = undefined;
        }
        if (track.trackMediaType === "audio") {
          await this.toggleNoiseCancellingExtension(false);
          this._microphoneTrack = undefined;
        }
      } catch (error) {
        if (track) {
          track.stop();
          track.close();
          if (track.trackMediaType === "video") {
            this._cameraTrack = undefined;
          }
          if (track.trackMediaType === "audio") {
            this._microphoneTrack = undefined;
          }
        }
        throw `_closeMediaTrack ERROR::${error}`;
      }
    }
  }
  private async unpublishTrack(track: ILocalTrack) {
    if (!track) return;
    try {
      const trackId = track.getTrackId();
      const idx = this._publishedTrackIds.indexOf(trackId);
      if (this.client && this.cameraTrack && this.cameraTrack.getTrackId() === trackId) {
        await this.client.unpublish([this.cameraTrack]);
      }
      if (this.client && this.microphoneTrack && this.microphoneTrack.getTrackId() === trackId) {
        await this.client.unpublish([this.microphoneTrack]);
      }
      this._publishedTrackIds.splice(idx, 1);
    } catch (error) {
      if (!track) return;
      const trackId = track.getTrackId();
      const idx = this._publishedTrackIds.indexOf(trackId);
      if (this.client && this.cameraTrack && this.cameraTrack.getTrackId() === trackId) {
        await this.client.unpublish([this.cameraTrack]);
      }
      if (this.client && this.microphoneTrack && this.microphoneTrack.getTrackId() === trackId) {
        await this.client.unpublish([this.microphoneTrack]);
      }
      this._publishedTrackIds.splice(idx, 1);
      throw `unpublishTrack ERROR::${error}`;
    }
  }
  private async _publishInternal(): Promise<any> {
    if (this.cameraTrack) {
      const trackId = this.cameraTrack.getTrackId();
      if (this._publishedTrackIds.indexOf(trackId) < 0) {
        await this.client.publish([this.cameraTrack]);
        this.publishedVideo = true;
        this._publishedTrackIds.push(trackId);
      }
    }
    if (this.microphoneTrack) {
      const trackId = this.microphoneTrack.getTrackId();
      if (this._publishedTrackIds.indexOf(trackId) < 0) {
        await this.client.publish([this.microphoneTrack]);
        this.publishedAudio = true;
        this._publishedTrackIds.push(trackId);
      }
    }
  }
  _publishedTrackIds: string[] = [];
  private async _publish(): Promise<any> {
    if (!this.joined || !this.client) return;
    if (this.client.connectionState == "CONNECTED") {
      await this._publishInternal();
      Logger.log("PUBLISH streams OK");
    } else {
      const retryCount = 3;
      let currentTry = 1;
      Logger.log("Agora client joined but connectionState not Connected, retry publish streams 3 times");
      // @ts-ignore: no overlap error
      while (this.client.connectionState != "CONNECTED" && currentTry <= retryCount) {
        setTimeout(async () => {
          Logger.log(`Agora client joined but connectionState not Connected, retry publish streams ${currentTry} time`);
          if (this.client.connectionState == "CONNECTED") {
            Logger.log("Retry publish streams successful");
            await this._publishInternal();
          } else {
            Logger.log("Retry publish streams NOT successful");
          }
        }, 1000);
        currentTry = +1;
      }
      // @ts-ignore: no overlap error
      if (this.client.connectionState != "CONNECTED" && currentTry > retryCount) {
        notification.error({ message: fmtMsg(TeacherClassError.PublishStreamAgoraServersError) });
      }
    }
  }
  async reset() {
    await this.destroyExtensions();
    try {
      if (this.cameraTrack) {
        await this.unpublishTrack(this.cameraTrack);
        Logger.log("Turn off camera");
      }
    } catch (error) {
      Logger.error(error);
    }
    try {
      if (this.microphoneTrack) {
        await this.unpublishTrack(this.microphoneTrack);
        Logger.log("Turn off audio");
      }
    } catch (error) {
      Logger.error(error);
    }
    this.client?.removeAllListeners();
    await this._client?.leave();
    this._client = undefined;
    await this.resetState();
  }
  async resetState() {
    this.publishedVideo = false;
    this.publishedAudio = false;
    this.joined = false;
    this.initialized = false;
    this._publishedTrackIds = [];
    this.cameraError = null;
    this.microphoneError = null;
    for (const item of this.subscribedAudios) {
      item.track.stop();
    }
    for (const item of this.subscribedVideos) {
      item.track.stop();
    }
    await this._closeMediaTrack(this.cameraTrack);
    await this._closeMediaTrack(this.microphoneTrack);
    this._cameraTrack = undefined;
    this._microphoneTrack = undefined;
    this.subscribedAudios = [];
    this.subscribedVideos = [];
    AgoraRTC.onMicrophoneChanged = undefined;
    AgoraRTC.onCameraChanged = undefined;
  }
  async leaveChannel() {
    await this._client?.leave();
    await this.resetState();
  }
  isCamEnable: boolean = false;
  async setCamera(options: { enable: boolean; videoEncoderConfigurationPreset?: string }) {
    try {
      this.isCamEnable = options.enable;
      if (this.isCamEnable) {
        await this.openCamera(options.videoEncoderConfigurationPreset);
        await this._publish();
      } else {
        if (!this.cameraTrack) return;
        await this.unpublishTrack(this.cameraTrack);
        await this._closeMediaTrack(this.cameraTrack);
      }
    } catch (err) {
      Logger.error(err);
      throw err;
    }
  }
  isMicEnable: boolean = false;
  async setMicrophone(options: { enable: boolean }) {
    try {
      this.isMicEnable = options.enable;
      if (this.isMicEnable) {
        await this.openMicrophone();
        await this._publish();
      } else {
        if (!this.microphoneTrack) return;
        await this.unpublishTrack(this.microphoneTrack);
        await this._closeMediaTrack(this.microphoneTrack);
      }
    } catch (err) {
      Logger.error(err);
      throw err;
    }
  }
  // sometimes, this method return undefined (this.client.remoteUsers is empty because this function call before the remoteUsers join and publish)
  // which causes the bug lost audio and video of remote user.
  // need to take into consider if this issue happening in dev
  private _getRemoteUser(userId: string): IAgoraRTCRemoteUser | undefined {
    if (!this.client) return undefined;
    return this.client.remoteUsers.find((e) => e.uid === userId);
  }
  async getBandwidth() {
    if (!this.client) return 0;
    const stats = this.client.getRTCStats();
    return stats.OutgoingAvailableBandwidth / 1024;
  }
  videos: string[] = [];
  audios: string[] = [];
  async triggerUpdateAudioAndVideoFeed() {
    await store.dispatch("calling/callingUpdateAudioAndVideoFeed");
  }
  updateFeedTimeOutId: any;
  async updateAudioAndVideoFeed(videos: Array<string>, audios: Array<string>) {
    if (this.updateFeedTimeOutId) {
      clearTimeout(this.updateFeedTimeOutId);
    }
    this.updateFeedTimeOutId = setTimeout(async () => {
      this.videos = videos;
      this.audios = audios;
      const unSubscribeVideos = this.subscribedVideos.filter((s) => videos.indexOf(s.userId) === -1).map((s) => s.userId);
      const unSubscribeAudios = this.subscribedAudios.filter((s) => audios.indexOf(s.userId) === -1).map((s) => s.userId);
      for (let studentId of unSubscribeVideos) {
        await this._unSubscribe(studentId, "video");
      }
      for (let studentId of unSubscribeAudios) {
        await this._unSubscribe(studentId, "audio");
      }
      for (let studentId of videos) {
        await this._subscribeVideo(studentId);
      }
      for (let studentId of audios) {
        await this._subscribeAudio(studentId);
      }
    }, 300);
  }
  reSubscribeAudiosCount: any = {};
  reSubscribeAudiosTimeout: any = {};
  async _subscribeAudio(userId: string, isAutoResubscribe = false) {
    if (!isAutoResubscribe) {
      if (this.reSubscribeAudiosTimeout[userId]) {
        clearTimeout(this.reSubscribeAudiosTimeout[userId]);
      }
      if (this.reSubscribeAudiosCount[userId]) {
        delete this.reSubscribeAudiosCount[userId];
      }
    }
    const subscribed = this.subscribedAudios.find((ele) => ele.userId === userId);
    if (subscribed?.track.isPlaying) return;
    const user = this._getRemoteUser(userId);
    if (!user?.hasAudio || !this.client) return;
    try {
      const remoteTrack = await this.client.subscribe(user, "audio");
      const speakerId = store.getters["speakerDeviceId"];
      if (speakerId) {
        await remoteTrack.setPlaybackDevice(speakerId);
      }
      remoteTrack.play();
      Logger.log(`audio of ${userId} played`);
      for (const [index, subscribedAudio] of this.subscribedAudios.entries()) {
        if (subscribedAudio.userId === userId) {
          this.subscribedAudios.splice(index, 1);
        }
      }
      this.subscribedAudios.push({ userId: userId, track: remoteTrack });
    } catch (err) {
      Logger.error(err);
      const inAudios = this.audios.find((i) => i === userId);
      if (inAudios) {
        if (this.reSubscribeAudiosCount[userId] === LIMIT_COUNT) {
          throw `Can't subscribe audio user with id ${userId}`;
        }
        if (!this.reSubscribeAudiosCount[userId]) {
          this.reSubscribeAudiosCount[userId] = INIT_COUNT;
          await this._subscribeAudio(userId, true);
        } else {
          this.reSubscribeAudiosCount[userId] = this.reSubscribeAudiosCount[userId] + 1;
          this.reSubscribeAudiosTimeout[userId] = setTimeout(async () => {
            await this._subscribeAudio(userId, true);
          }, 1000);
        }
      }
    }
  }
  reSubscribeVideosCount: any = {};
  reSubscribeVideosTimeout: any = {};
  checkIsVideoPlaying(track: IRemoteVideoTrack) {
    if (!track?.isPlaying) return false;
    const status = track.getVideoElementVisibleStatus();
    // reason "UNMOUNTED" is missing on agora type
    // @ts-ignore
    return !!(status && (status.visible || status.reason !== "UNMOUNTED"));
  }
  async _subscribeVideo(userId: string, isAutoResubscribe = false) {
    const user = this._getRemoteUser(userId);
    if (!user || !user.hasVideo || !this.client || !document.getElementById(userId)) return;
    if (!isAutoResubscribe) {
      if (this.reSubscribeVideosTimeout[userId]) {
        clearTimeout(this.reSubscribeVideosTimeout[userId]);
      }
      if (this.reSubscribeVideosCount[userId]) {
        delete this.reSubscribeVideosCount[userId];
      }
    }
    const subscribed = this.subscribedVideos.find((ele) => ele.userId === userId);
    if (subscribed) {
      // check if the video has display or not; if video not display -> unsubscribe and subscribe again
      if (this.checkIsVideoPlaying(subscribed.track)) return;
      await this._unSubscribe(userId, "video");
    }
    try {
      const remoteTrack = await this.client.subscribe(user, "video");
      if (this.checkIsVideoPlaying(remoteTrack) || !this.joined) return;
      if (!document.getElementById(userId)) return;
      if (this.options.user?.role !== "host") {
        const isTeacher = store.getters["studentRoom/teacher"]?.id === user.uid;
        remoteTrack.play(userId, { mirror: isTeacher ? this.isRemoteMirror : this.isMirror });
      } else {
        remoteTrack.play(userId, { mirror: this.isRemoteMirror });
      }
      Logger.log(`AGORA: Video id ${userId} played`);
      for (const [index, subscribedVideo] of this.subscribedVideos.entries()) {
        if (subscribedVideo.userId === userId) {
          this.subscribedVideos.splice(index, 1);
        }
      }
      this.subscribedVideos.push({ userId: userId, track: remoteTrack });
    } catch (err) {
      Logger.error(err);
      const inVideos = this.videos.find((i) => i === userId);
      if (inVideos) {
        if (this.reSubscribeVideosCount[userId] === LIMIT_COUNT) {
          throw `Can't subscribe video user with id ${userId}`;
        }
        if (!this.reSubscribeVideosCount[userId]) {
          this.reSubscribeVideosCount[userId] = INIT_COUNT;
          await this._subscribeVideo(userId, true);
        } else {
          this.reSubscribeVideosCount[userId] = this.reSubscribeVideosCount[userId] + 1;
          const timeoutId = setTimeout(async () => {
            await this._subscribeVideo(userId, true);
          }, 1000);
          this.reSubscribeVideosTimeout[userId] = timeoutId;
        }
      }
    }
  }
  async _unSubscribe(studentId: string, mediaType: "audio" | "video") {
    try {
      const user = this._getRemoteUser(studentId);
      if (user) await this.client.unsubscribe(user, mediaType);
      this._removeMediaTrack(studentId, mediaType);
    } catch (e) {
      Logger.error(e);
    }
  }
  private _removeMediaTrack(studentId: string, mediaType: "audio" | "video") {
    if (mediaType === "video") {
      const trackIndex = this.subscribedVideos.findIndex((ele) => ele.userId === studentId);
      if (trackIndex === -1) return;
      this.subscribedVideos[trackIndex].track.stop();
      this.subscribedVideos.splice(trackIndex, 1);
    } else {
      const trackIndex = this.subscribedAudios.findIndex((ele) => ele.userId === studentId);
      if (trackIndex === -1) return;
      this.subscribedAudios[trackIndex].track.stop();
      this.subscribedAudios.splice(trackIndex, 1);
    }
  }
  setupHotPluggingDevice = (type: CallingDeviceType) => {
    if (type === CallingDeviceType.MICROPHONE) {
      if (AgoraRTC.onMicrophoneChanged) return;
      AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
        let nextDeviceId = undefined;
        if (changedDevice.state === "ACTIVE") {
          nextDeviceId = changedDevice.device.deviceId;
        } else if (!this.microphoneTrack || changedDevice.device.label === this.microphoneTrack?.getTrackLabel()) {
          const oldMicrophones = await AgoraRTC.getMicrophones();
          nextDeviceId = oldMicrophones[0]?.deviceId;
        }
        nextDeviceId && (await store.dispatch("setMicrophoneDeviceId", nextDeviceId));
        await store.dispatch("calling/callingUpdateDevice", { deviceType: CallingDeviceType.MICROPHONE });
      };
    }
    if (type === CallingDeviceType.CAMERA) {
      if (AgoraRTC.onCameraChanged) return;
      AgoraRTC.onCameraChanged = async (changedDevice) => {
        let nextDeviceId = undefined;
        if (changedDevice.state === "ACTIVE") {
          nextDeviceId = changedDevice.device.deviceId;
        } else if (!this.cameraTrack || changedDevice.device.label === this.cameraTrack?.getTrackLabel()) {
          const oldCameras = await AgoraRTC.getCameras();
          nextDeviceId = oldCameras[0]?.deviceId;
        }
        nextDeviceId && (await store.dispatch("setCameraDeviceId", nextDeviceId));
        await store.dispatch("calling/callingUpdateDevice", { deviceType: CallingDeviceType.CAMERA, isTurnOff: !this.cameraTrack });
      };
    }
  };
  updateCameraDevice = async () => {
    const camId = store.getters["cameraDeviceId"];
    if (camId) {
      try {
        await this.cameraTrack?.setDevice(camId);
      } catch (error) {
        Logger.error(error);
      }
    }
  };
  updateMicrophoneDevice = async () => {
    const microphoneId = store.getters["microphoneDeviceId"];
    if (microphoneId) {
      try {
        await this.microphoneTrack?.setDevice(microphoneId);
      } catch (error) {
        Logger.error(error);
      }
    }
  };
  updateSpeakerDevice = async () => {
    const audios = this.audios;
    for (let studentId of audios) {
      await this._unSubscribe(studentId, "audio");
    }
    for (let studentId of audios) {
      await this._subscribeAudio(studentId);
    }
  };

  async destroyExtensions() {
    if (processor) {
      await this.toggleNoiseCancellingExtension(false);
      processor = null;
    }
  }

  async settingNoiseCancelling(audioTrack?: IMicrophoneAudioTrack) {
    if(!extension) {
      extension = new AIDenoiserExtension({
        assetsPath: `${process.env.VUE_APP_CLIENT_URL}external`,
      });
      // Check compatibility
      if (!extension.checkCompatibility()) {
        // The extension might not be supported in the current browser. You can stop executing further code logic
        Logger.error("Does not support AI Denoiser!");
        return;
      }
      AgoraRTC.registerExtensions([extension]);
    }
    if(!processor) {
      processor = extension.createProcessor();
      processor.on('loaderror', (e: Error) => {
        Logger.error(e);
        processor = null;
      });
      processor.on('overload', async () => {
        Logger.log("AI Noise Cancelling overload!!!");
        await processor?.setMode(AIDenoiserProcessorMode.STATIONARY_NS);
        await processor?.disable();
      });
    }
    if(!audioTrack || !extension || !processor) return;
    audioTrack.pipe(processor).pipe(audioTrack.processorDestination);
    await this.toggleNoiseCancellingExtension(true);
  }

  async toggleNoiseCancellingExtension(enable: boolean) {
    if (!processor) return;
    if (enable && !processor.enabled) {
      await processor.enable();
      await processor.setMode(AIDenoiserProcessorMode.NSNG);
      await processor.setLevel(AIDenoiserProcessorLevel.AGGRESSIVE);
      return;
    }
    processor.enabled && (await processor.disable());
  }
}
